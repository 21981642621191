import { useContext } from "react";
import Column from "./column";

import { SettingsContext } from "../App";
import ColumnP from "./ColumnP";

function ContentP({ setCustomAlert, products, advanceStatus, backStatus }) {
	const { settings } = useContext(SettingsContext);
	const formats = settings.format.split(",");

	return (
		<div className="content">
			{settings.template.columns.map((column, i) => (
				<div key={i} className={"column column-size-" + formats[i] + " " + column.status}>
					<ColumnP
						index={i}
						products={products[column.status] || []}
						status={column.status}
						label={column.label}
						setCustomAlert={setCustomAlert}
						advanceStatus={advanceStatus}
						backStatus={backStatus}
					/>
				</div>
			))}
		</div>
	);
}

export default ContentP;
