import { useContext } from "react";
import Api from "./api/api";
import { SettingsContext } from "../App";

export default function ProductStatusPopup() {
	const { settings, setLoading, selectedProduct, setSelectedProduct, shop } =
		useContext(SettingsContext);

	async function advanceStatus() {
		console.log("advanceStatus");
		setLoading(true);
		var response = await Api.postProtected("/orders/edit_products_status/", {
			shop_id: shop.id,
			status: get_next_status(),
			products_ids: selectedProduct.ids,
		});
		setLoading(false);
		if (response.success == 0) alert(response.error);
		else setSelectedProduct(false);
	}

	async function backStatus() {
		console.log("backStatus");
		setLoading(true);
		var response = await Api.postProtected("/orders/edit_products_status/", {
			shop_id: shop.id,
			status: get_prev_status(),
			products_ids: selectedProduct.ids,
		});
		setLoading(false);
		if (response.success == 0) alert(response.error);
		else setSelectedProduct(false);
	}

	const get_next_status = () => {
		if (settings.template.columns[selectedProduct.index + 1] == undefined) return false;
		else return settings.template.columns[selectedProduct.index + 1].status;
	};

	const get_prev_status = () => {
		if (settings.template.columns[selectedProduct.index - 1] == undefined) return false;
		else return settings.template.columns[selectedProduct.index - 1].status;
	};
	return (
		<div className="status-popup">
			<div className="content">
				<div className="number">{selectedProduct.qty}</div>
				<div className="name">{selectedProduct.name}</div>
				{get_next_status() && (
					<button className="btn btn-success btn-block" onClick={advanceStatus}>
						Avanza
					</button>
				)}
				{get_prev_status() && (
					<button className="btn btn-danger btn-block" onClick={backStatus}>
						Arretra
					</button>
				)}
				<button
					className="btn btn-secondary btn-block"
					onClick={() => setSelectedProduct(false)}
				>
					Annulla
				</button>
			</div>
		</div>
	);
}
