import { useContext, useRef, useState } from "react";
import Settings from "./settings";
import Clock from "./clock";
import UserIcon from "./icons/UserIcon";
import GearIcon from "./icons/GearIcon";
import LogoutIcon from "./icons/LogoutIcon";
import { UserContext } from "../App";
import { ShopContext } from "../App";
import { SettingsContext } from "../App";
import logoWhite from "../images/logo_white.png";
import ClockIcon from "./icons/ClockIcon";
import ShopIcon from "./icons/ShopIcon";
import UtensilIcon from "./icons/UtensilIcon";

function Header({ setCustomAlert, advanceStatus, orders }) {
	const { user, setUser } = useContext(UserContext);
	const { shop } = useContext(ShopContext);
	const {
		settings,
		setSettings,
		order,
		setOrder,
		setConfirmData,
		autofocus,
		setAutofocus,
		settingsVisible,
		setSettingsVisible,
		listMode,
		setListMode,
		productionCenter,
	} = useContext(SettingsContext);
	const orderId = useRef();

	function handlePress() {
		setCustomAlert({
			title: "Sei sicuro?",
			message: false,
			visible: true,
			onConfirm: () => {
				localStorage.removeItem("user");
				setUser(false);
			},
			onCancel: () => { },
		});
	}

	function handleSrc(e) {
		console.log("handleSrc");
		e.preventDefault();
		console.log(orderId.current.value);
		const confirmed = orders.confirmed.filter((o) => {
			return o.id == orderId.current.value;
		});
		const preparation = orders.preparation.filter((o) => {
			return o.id == orderId.current.value;
		});
		const ready = orders.ready.filter((o) => {
			return o.id == orderId.current.value;
		});
		const filled = orders.filled.filter((o) => {
			return o.id == orderId.current.value;
		});
		console.log(confirmed, preparation, ready, filled);
		const final = confirmed.concat(preparation, ready, filled);
		console.log(final);
		if (final.length > 0) {
			if (!order) setOrder(final[0]);
			else advanceStatus(order);
		} else setConfirmData({ title: "Non trovato", yesActionLabel: "Chiudi" });
		document.getElementById("qrForm").reset();
	}

	return (
		<>
			<div>
				<div className="header">
					<div style={{ display: "flex", alignItems: "center" }}>
						<button
							onClick={() =>
								setListMode(listMode == "orders" ? "products" : "orders")
							}
							className="btn btn-secondary me-2"
						>
							Vista: {listMode == "orders" ? "Ordini" : "Prodotti"}
						</button>
						<button
							onClick={() => setAutofocus(!autofocus)}
							className="btn btn-secondary me-2"
						>
							Autofocus: {autofocus ? "ON" : "OFF"}
						</button>
						<form id="qrForm" onSubmit={handleSrc} autoComplete="off">
							<input
								id="qrFormField"
								type="text"
								ref={orderId}
								onBlur={() => {
									if (autofocus) {
										console.log("refocus");
										document.getElementById("qrFormField").focus();
									}
								}}
								autoFocus={autofocus}
								autoComplete="do-not-autofill"
								inputMode={autofocus ? "none" : "text"}
							/>
						</form>
						<div className="clock">
							<ClockIcon className="icon me-1" /> <Clock />
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "center" }} className="info-access">
						<span className="btn btn-secondary me-2">
							<ShopIcon className="icon" /> {user.name} {user.surname}
						</span>
						<span className="btn btn-secondary me-2"><UserIcon className="icon" /> {shop.name}</span>
						{productionCenter && <span className="btn btn-secondary me-2"><UtensilIcon className="icon" /> {productionCenter.name}</span>}
						{/*<button
						onClick={() => setView(view == "" ? "products" : "")}
						className="btn btn-secondary me-2"
					>
						View
					</button>*/}
						<button
							className="btn btn-secondary me-2"
							onClick={() => setSettingsVisible(true)}
						>
							<GearIcon className="icon" />
						</button>
						<button className="btn btn-secondary" onClick={handlePress}>
							<LogoutIcon className="icon" />
						</button>
						<div className="logo-header">
							<img src={logoWhite} />
						</div>
					</div>
				</div>
			</div>
			<Settings
				visible={settingsVisible}
				setVisible={setSettingsVisible}
				settings={settings}
				setSettings={setSettings}
			></Settings>
		</>
	);
}

export default Header;
