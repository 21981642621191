import ClockIcon from "./icons/ClockIcon";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { SettingsContext } from "../App";

function Timer({ order }) {
	const { settings } = useContext(SettingsContext);
	const alertTime = settings.warning_time || 5;
	const expirationTime = settings.alert_time || 10;
	const [message, setMessage] = useState(false);
	const [style, setStyle] = useState("");

	useEffect(() => {
		check();
		let expireTimeout = window.setInterval(check, 60 * 1000);
		return () => clearInterval(expireTimeout);
	}, [order, settings]);

	const check = () => {
		if (order.status == "ready" || order.status == "filled" || !order.timer_date)
			setMessage(false);
		else {
			if (order.delivery_type == "delivery") {
				console.log(settings.preorder_delivery);
				const now = moment();
				const to_be_ready_at = moment(order.timer_date).subtract(
					settings.preorder_delivery,
					"minutes"
				);
				const diff = now.diff(to_be_ready_at, "minutes");
				if (diff >= alertTime && diff < expirationTime) {
					setStyle("warning-box");
				} else if (diff >= expirationTime) {
					setStyle("alert-box");
				} else {
					setStyle("success-box");
				}
				if (diff > 0) setMessage("Ritardo di " + Math.abs(diff) + "'");
				else setMessage("Pronto in " + Math.abs(diff) + "'");
			} else {
				const now = moment();
				const to_be_ready_at = moment(order.timer_date);
				const diff = now.diff(to_be_ready_at, "minutes");
				if (diff >= alertTime && diff < expirationTime) {
					setStyle("warning-box");
				} else if (diff >= expirationTime) {
					setStyle("alert-box");
				} else {
					setStyle("success-box");
				}
				if (diff > alertTime) setMessage("Ritardo di " + Math.abs(diff) + "'");
				else setMessage("Pronto in " + Math.abs(diff) + "'");
			}
		}
	};
	if (!message) return null;

	return (
		<div className="timer-box">
			<div className={style}>
				<ClockIcon className="icon me-1" />
				{message}
			</div>
		</div>
	);
}

export default Timer;
