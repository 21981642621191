function Popup({ customAlert, setCustomAlert }) {
	function onConfirm() {
		customAlert.onConfirm();
		setCustomAlert({ visible: false });
	}
	function onCancel() {
		customAlert.onCancel();
		setCustomAlert({ visible: false });
	}

	return (
		<>
			{customAlert.visible && (
				<div className="popup">
					<div className="content">
						<h4 className="mb-3">{customAlert.title}</h4>
						{customAlert.message && <p>{customAlert.message}</p>}
						<div className="d-grid gap-2">
							{customAlert.onConfirm && (
								<button className="btn btn-primary" onClick={onConfirm}>
									{customAlert.confirmLabel ? customAlert.confirmLabel : "Si"}
								</button>
							)}
							{customAlert.onCancel && (
								<button className="btn btn-secondary" onClick={onCancel}>
									{customAlert.cancelLabel ? customAlert.cancelLabel : "No"}
								</button>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Popup;
