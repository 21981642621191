import "../css/confirm.css";

function Confirm({ data, setData }) {
	const handleYes = () => {
		setData(false);
	};

	const handleNo = () => {
		setData(false);
	};

	if (!data) return null;
	return (
		<div className="confirm-popup">
			<div className="content">
				{data.title && <div className="title">{data.title}</div>}
				{data.message && <div className="message">{data.message}</div>}
				<div className="buttons">
					{data.noAction && (
						<button onClick={handleNo}>
							{data.noActionLabel ? data.noActionLabel : "No"}
						</button>
					)}
					<button onClick={handleYes}>
						{data.yesActionLabel ? data.yesActionLabel : "Si"}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Confirm;
