import logo from "../images/logo.png";

function Splash() {
	return (
		<div className="splash">
			<div className="content">
				<img src={logo} />
			</div>
		</div>
	);
}

export default Splash;
