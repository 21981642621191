import React, { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Api from "./api/api";
import logo from "../images/logo.png";

import { SettingsContext, UserContext } from "../App";
import { ShopContext } from "../App";

function Login() {
	const { productionCenter, setProductionCenter } = useContext(SettingsContext);
	const { setUser } = useContext(UserContext);
	const { shop, setShop, productionCenters } = useContext(ShopContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [shops, setShops] = useState([]);

	useEffect(() => {
		load_shops();
	}, []);

	async function handleSubmit(e) {
		e.preventDefault();
		var request = {
			email: email,
			password: password,
		};

		const login = await Api.login(request);
		if (login.success == 0) setError(login.error);
		else {
			const decoded = jwtDecode(login.token);
			localStorage.setItem("km-user", login.token);
			const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else {
				setUser(response.data);
			}
		}
	}

	async function load_shops() {
		var response = await Api.post("/shops/list/");
		setShops(response.rows);
	}

	async function findAndSetShop(shop_id) {
		console.log(shop_id);
		if (shop_id == "") {
			localStorage.removeItem("shop_id");
			setShop(false);
		} else {
			for (var shop of shops) {
				if (shop.id == shop_id) {
					localStorage.setItem("shop_id", shop_id);
					setShop(shop);
				}
			}
		}
	}

	async function findAndSetProductionCenter(production_center_id) {
		console.log(production_center_id);
		if (production_center_id == "") {
			localStorage.removeItem("production_center_id");
			setProductionCenter(false);
		} else {
			for (var production_center of productionCenters) {
				if (production_center.id == production_center_id) {
					localStorage.setItem("production_center_id", production_center_id);
					setProductionCenter(production_center);
				}
			}
		}
	}

	return (
		<div className="login">
			<div className="content">
				<form onSubmit={handleSubmit}>
					<div className="login-logo">
						<img src={logo} />
					</div>
					<h4>KDS - Kitchen Manager</h4>
					<div className="mb-3">
						<label htmlFor="shop_id" className="form-label">
							Negozio:
						</label>
						<select
							className="form-select"
							onChange={(e) => findAndSetShop(e.target.value)}
							value={shop.id}
							required
						>
							<option key={0} value=""></option>
							{shops.map((el_shop) => (
								<option key={el_shop.id} value={el_shop.id}>
									{el_shop.name}
								</option>
							))}
						</select>
					</div>
					{productionCenters && (
						<div className="mb-3">
							<label htmlFor="shop_id" className="form-label">
								Centro di produzione:
							</label>
							<select
								className="form-select"
								onChange={(e) => findAndSetProductionCenter(e.target.value)}
								value={productionCenter ? productionCenter.id : ""}
							>
								<option key={0} value="">
									Tutti
								</option>
								{productionCenters.map((el_shop) => (
									<option key={el_shop.id} value={el_shop.id}>
										{el_shop.name}
									</option>
								))}
							</select>
						</div>
					)}
					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							Email:
						</label>
						<input
							type="email"
							className="form-control"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="password" className="form-label">
							Password:
						</label>
						<input
							type="password"
							className="form-control"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							autoComplete="current password"
						/>
					</div>
					{error && <div className="bg-danger text-light mb-3 p-2">{error}</div>}
					<div className="d-grid gap-2">
						<button type="submit" className="btn btn-primary">
							Accedi
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Login;
