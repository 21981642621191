import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../App";
import Masonry from "react-responsive-masonry";
import Timer from "./Timer";

function ColumnP({ index, products, status, label, setCustomAlert, advanceStatus, backStatus }) {
	const { settings, audio } = useContext(SettingsContext);
	const [counter, setCounter] = useState(0);
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		setCounter(products.length);
		const prevCount = localStorage.getItem("count-" + status);
		if (prevCount !== null && status == "confirmed") {
			console.log(
				parseInt(prevCount),
				products.length,
				parseInt(prevCount) != products.length
			);
			if (parseInt(prevCount) != products.length) {
				console.log("Alert!", status);
				audio.current.play().catch((err) => {
					console.log("Errore", err);
					setTimeout(function () {}, 500);
				});
			}
		}
		localStorage.setItem("count-" + status, products.length);
	}, [products]);

	useEffect(() => {
		console.log("counter", status, "set to", counter);
	}, [counter]);

	return (
		<>
			<button
				className="title"
				onClick={() => {
					if (status != "confirmed") setVisible(visible ? false : true);
				}}
			>
				<span className="tag">{counter}</span>
				{visible && <h3>{label}</h3>}
			</button>

			{visible && (
				<div className="masonry product-list">
					<Masonry
						columnsCount={index == 0 ? parseInt(settings.format) : 1}
						gutter="10px"
						sequential={true}
					>
						{products.map((product, i) => (
							<Product
								product={product}
								key={i}
								setCustomAlert={setCustomAlert}
								advanceStatus={advanceStatus}
								backStatus={backStatus}
								colIndex={index}
							/>
						))}
					</Masonry>
				</div>
			)}
		</>
	);
}

function Product({ product, colIndex }) {
	const { settings, setSelectedProduct } = useContext(SettingsContext);

	return (
		<button
			className={product.status}
			onClick={() => setSelectedProduct({ ...product, index: colIndex })}
		>
			<div className="content">
				<div className="qty">{product.qty}</div>
				<div className="inner">
					<div className="name">{product.name}</div>
					<div
						style={{
							fontSize:
								settings.font_size_variant != ""
									? settings.font_size_variant + "px"
									: "12px",
						}}
					>
						{product.variants.map((variant, i) => (
							<div key={i}>{variant.name}</div>
						))}
						{product.products.map((subproduct, i) => (
							<div key={i}>{subproduct.name}</div>
						))}
						{product.notes && (
							<div className={settings.highlight_notes == 1 ? "highlight" : ""}>
								<b>Info prodotto:</b>
								<div
									dangerouslySetInnerHTML={{
										__html: product.notes,
									}}
								/>
							</div>
						)}
						{product.order_notes && (
							<div className={settings.highlight_notes == 1 ? "highlight" : ""}>
								<b>Info ordine:</b>
								<div
									dangerouslySetInnerHTML={{
										__html: product.order_notes,
									}}
								/>
							</div>
						)}
						{settings.show_ingredients && product.ingredients?.length > 0 && (
							<>
								<div>
									<b>Ingredienti prodotto</b>
								</div>
								{product.ingredients.map((ingredient, i) => (
									<div key={i}>{ingredient.name}</div>
								))}
							</>
						)}
					</div>
				</div>
			</div>
			<Timer order={product} />
		</button>
	);
}

export default ColumnP;
