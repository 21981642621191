import LoadingIcon from "./icons/LoadingIcon";

function Loading() {
	return (
		<div className="loading">
			<div className="content">
				<LoadingIcon className="icon icon-l icon-spin" />
			</div>
		</div>
	);
}

export default Loading;
