import { useContext } from "react";
import { SettingsContext } from "../App";
import Status from "./Status";
import CloseIcon from "./icons/CloseIcon";
import Moment from "react-moment";

function OrderPopup({ advanceStatus, backStatus, setCustomAlert }) {
	const { settings, order, setOrder } = useContext(SettingsContext);

	const statusClass = (function () {
		if (order.status === "confirmed") return "bg-1";
		if (order.status === "preparation") return "bg-2";
		if (order.status === "ready") return "bg-3";
		if (order.status === "filled") return "bg-4";
		return "";
	})();

	const statusName = (function () {
		if (order.status === "confirmed") return "Attesa";
		if (order.status === "preparation") return "Preparazione";
		if (order.status === "ready") return "Pronto";
		if (order.status === "filled") return "Completato";
		return "";
	})();

	async function handlePress(order) {
		console.log("handlePress");
		if (settings.disable_confirm == 0) {
			setCustomAlert({
				title: "Sei sicuro?",
				visible: true,
				onConfirm: () => {
					advanceStatus(order);
				},
				onCancel: () => { },
			});
		} else {
			advanceStatus(order);
		}
	}

	async function handleBack(order) {
		console.log("handleBack");
		setCustomAlert({
			title: "Sei sicuro?",
			visible: true,
			onConfirm: () => {
				backStatus(order);
			},
			onCancel: () => { },
		});
	}

	return (
		<div className="popup large">
			<div className="content">
				<button className="close-button" onClick={() => setOrder(false)}>
					<CloseIcon className="icon icon-l" />
				</button>
				<h4>
					Ordine N°{order.number}
					<span className="small"> - ID: {order.id}</span>
				</h4>
				<Moment format={"DD/MM/YYYY HH:mm"} locale="it">
					{order.delivery_date}
				</Moment>

				{order.table_id && <h6>Tavolo: {order.table.name}</h6>}
				<div className={"status-box " + statusClass}>{statusName}</div>
				<h6><b>Prodotti</b></h6>
				<div className="mb-2">
					{order.products.map((product, i) => (
						<div key={i} className="horizzontal-group">
							<Status status={product.status} />
							<div className="mb-2">
								<span
									className="product-tag"
									style={{
										fontSize:
											settings.font_size_product != ""
												? settings.font_size_product - 4 + "px"
												: "12px",
									}}
								>
									{product.qty}
								</span> {product.name}
								{product.notes && (
									<div className="variant ingredients">
										<b>Info prodotto</b>
										<div
											className="variant"
											style={{
												fontSize:
													settings.font_size_variant != ""
														? settings.font_size_variant + "px"
														: "12px",
											}}
											dangerouslySetInnerHTML={{ __html: product.notes }}
										/>
									</div>
								)}
								{settings.show_ingredients && product.ingredients.length > 0 && (
									<div>
										<div className="variant variant-title">Ingredienti prodotto</div>
										{product.ingredients.map((ingredient, i) => (
											<div
												className="variant"
												style={{
													fontSize:
														settings.font_size_variant != ""
															? settings.font_size_variant + "px"
															: "12px",
												}}
											>
												{ingredient.name}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					))}
				</div>
				{order.notes && (
					<div className="mb-2 highlight">
						<b>Note cliente:</b>
						<div className="small">{order.notes}</div>
					</div>
				)}

				<div className="buttons">
					<div>
						{order.status != "confirmed" && (
							<button
								className="btn btn-secondary w-100"
								onClick={() => handleBack(order)}
							>
								Arretra
							</button>
						)}
					</div>
					<div>
						{order.status != "filled" && (
							<button
								className="btn btn-success w-100"
								onClick={() => handlePress(order)}
							>
								Avanza
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrderPopup;
