import { useEffect, useState } from "react";

function Clock() {
	const [clockState, setClockState] = useState();

	useEffect(() => {
		let myInterval = setInterval(() => {
			const date = new Date();
			setClockState(date.toLocaleTimeString());
		}, 1000);
		return () => {
			console.log("clearInterval Clock");
			clearInterval(myInterval);
		};
	}, []);

	return <span>{clockState}</span>;
}

export default Clock;
