function Status({ status }) {
	const className = (function () {
		if (status === "confirmed") return "c-1";
		if (status === "preparation") return "c-2";
		if (status === "ready") return "c-3";
		if (status === "filled") return "c-4";
		return "";
	})();

	const title = (function () {
		if (status === "confirmed") return "Attesa";
		if (status === "preparation") return "Prepara";
		if (status === "ready") return "Pronto";
		if (status === "filled") return "Completato";
		return "";
	})();

	return <div className={className + " status-tag"}>{title}</div>;
}

export default Status;
