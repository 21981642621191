import React, { useContext, useEffect, useState } from "react";

import Api from "./api/api";
import Content from "./content";
import Footer from "./footer";
import Header from "./header";
import Popup from "./popup";

import { SettingsContext } from "../App";
import OrderPopup from "./OrderPopup";
import ContentP from "./ContentP";

function Main() {
	const {
		settings,
		setLoading,
		order,
		setOrder,
		orders,
		listMode,
		products,
		productionCenter,
		customAlert,
		setCustomAlert,
	} = useContext(SettingsContext);

	async function advanceStatus(order) {
		console.log("advanceStatus");
		var status = get_next_status(order.status);
		if (status) {
			setLoading(true);
			var response = await Api.postProtected("/orders/edit/" + order.id + "/?debug=1", {
				status: status,
				production_center_id: productionCenter ? productionCenter.id : "",
				flow: order.flow,
			});
			setLoading(false);
			if (response.success == 1) {
				if (order) setOrder(false);
			} else alert(response.error);
		} else
			setCustomAlert({
				title: "Non puoi avanzare oltre questo ordine",
				message: false,
				onConfirm: () => {},
				onCancel: false,
				visible: true,
				confirmLabel: "Ok",
			});
	}

	async function backStatus(order) {
		console.log("backStatus");
		var status = get_prev_status(order.status);
		if (status) {
			setLoading(true);
			var response = await Api.postProtected("/orders/edit/" + order.id + "/?debug=1", {
				status: status,
				production_center_id: productionCenter ? productionCenter.id : "",
				flow: order.flow,
			});
			setLoading(false);
			if (response.success == 1) {
				if (order) setOrder(false);
			} else alert(response.error);
		} else
			setCustomAlert({
				title: "Non puoi arretrare oltre questo ordine",
				message: false,
				onConfirm: () => {},
				onCancel: false,
				visible: true,
				confirmLabel: "Ok",
			});
	}

	const get_next_status = (actual_status) => {
		const cols = settings.template.columns;
		let actualColIndex = false;
		let i = 0;
		for (const col of cols) {
			if (col.status == actual_status) actualColIndex = i;
			i++;
		}
		console.log(actualColIndex);
		console.log(cols[actualColIndex]);
		console.log(cols[actualColIndex + 1]);
		if (cols[actualColIndex + 1] == undefined) return false;
		else return cols[actualColIndex + 1].status;
	};

	const get_prev_status = (actual_status) => {
		const cols = settings.template.columns;
		let actualColIndex = false;
		let i = 0;
		for (const col of cols) {
			if (col.status == actual_status) actualColIndex = i;
			i++;
		}
		console.log(actualColIndex);
		console.log(cols[actualColIndex]);
		console.log(cols[actualColIndex - 1]);
		if (cols[actualColIndex - 1] == undefined) return false;
		else return cols[actualColIndex - 1].status;
	};

	return (
		<>
			<div className="main">
				{listMode == "orders" && (
					<Content
						setCustomAlert={setCustomAlert}
						orders={orders}
						advanceStatus={advanceStatus}
						backStatus={backStatus}
					/>
				)}
				{listMode == "products" && (
					<ContentP
						setCustomAlert={setCustomAlert}
						products={products}
						advanceStatus={advanceStatus}
						backStatus={backStatus}
					/>
				)}
				<Footer />
				<Header
					setCustomAlert={setCustomAlert}
					advanceStatus={advanceStatus}
					orders={orders}
				/>
			</div>
			{order && (
				<OrderPopup
					advanceStatus={advanceStatus}
					backStatus={backStatus}
					setCustomAlert={setCustomAlert}
				/>
			)}
			<Popup customAlert={customAlert} setCustomAlert={setCustomAlert} />
		</>
	);
}

export default Main;
