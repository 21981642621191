// icon:clock | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from "react";

function ClockIcon(props) {
	return (
		<svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props}>
			<path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0s256 114.6 256 256-114.6 256-256 256zm-24-392v136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
		</svg>
	);
}

export default ClockIcon;
