import { io } from "socket.io-client";

const hostname = window.location.hostname;
let socketUrl = false;
if (hostname.includes("localhost")) {
	socketUrl = "https://ws.yellgo.cloud:3200";
	//socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
} else if (hostname.includes(".demo.")) socketUrl = "https://ws.yellgo.cloud:3100";
else if (hostname.includes(".devaws.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else if (hostname.includes(".dev.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else socketUrl = "https://ws.yellgo.cloud";

export const socket = io(socketUrl);
