function Footer() {
	return (
		<div className="footer">
			<div className="legenda-title">LEGENDA:</div>
			<div className="legenda">
				<div className="waiting-dot">
					<div></div>
					<span>In attesa</span>
				</div>
				<div className="preparation-dot">
					<div></div>
					<span>In preparazione</span>
				</div>
				<div className="ready-dot">
					<div></div>
					<span>Pronto</span>
				</div>
				<div className="complete-dot">
					<div></div>
					<span>Completato</span>
				</div>
			</div>
		</div>
	);
}

export default Footer;
